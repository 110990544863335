@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

html,
body,
#root {
  font-family: 'VT323', monospace;
  color: #fff;
  height: 100vh !important;
  min-height: 100vh !important;
}

#root {
  font-family: 'VT323', monospace;
  color: #fff;
  height: 100vh !important;
  min-height: 100vh !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover-lift {
  transition: transform 0.3s ease-out;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

.section-border {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.phone-container {
  perspective: 1000px;
  transition: all 0.3s ease-out;
  height: 50vh;
  width: calc(50vh * 9 / 18);
  max-width: 90vw;
}

.phone-frame {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  box-shadow: 0 0 0 8px #1a1a1a, 0 0 0 0px #000,
    0 0 0 20px rgba(255, 255, 255, 0.08);
  overflow: hidden;
  position: relative;
}

.phone-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 1.5rem;
  overflow: hidden;
}

.phone-content {
  height: 100%;
  overflow-y: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Custom scrollbar for WebKit browsers */
.phone-content::-webkit-scrollbar {
  width: 4px;
}

.phone-content::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.phone-content::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 2px;
}

.phone-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#qc-cmp2-container {
  position: fixed !important;
  bottom: 0 !important;
  top: auto !important;
  z-index: 9999 !important;
  width: 100% !important;
  max-width: 100% !important;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

#qc-cmp2-container .qc-cmp2-summary-buttons {
  display: flex;
  justify-content: center;
}
