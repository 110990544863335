.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
}

.tv-carousel {
  display: flex;
  overflow-x: scroll;
  padding: 12px;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tv-carousel::-webkit-scrollbar {
  display: none; /* Ocultar scrollbar en navegadores basados en WebKit */
}

.tv {
  flex: 0 0 auto;
  transition: transform 0.3s;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 24px;
  margin-bottom: 12px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.match {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.team {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.5));
}

.team-line {
  font-weight: bold;
  color: #fff;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.5));
}

.tv img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.tv:hover {
  transform: scale(1.1);
}

.tv-title {
  user-select: none; /* Evitar la selección de texto */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
  margin: 0;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10;
  width: 30px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-button.left {
  left: 0px;
}

.scroll-button.right {
  right: 0px;
}

.scroll-button:disabled {
  display: none;
}
